var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "mx-auto col-lg-8",
      attrs: { id: "application-form-wrapper" }
    },
    [
      !_vm.finished
        ? _c(
            "form",
            {
              staticClass: "application-form",
              attrs: { id: "application-form", method: "POST" },
              on: { submit: _vm.validateOnSubmit }
            },
            [
              _c(
                "fieldset",
                { staticClass: "margin-bottom" },
                [
                  _vm._l(_vm.sortedTimings, function(timing) {
                    return _c(
                      "div",
                      { key: timing.id, staticClass: "row timing-row" },
                      [
                        _c("div", { staticClass: "box box-info col-12" }, [
                          _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "col-md" }, [
                              !_vm.course.is_event
                                ? _c("span", { staticClass: "box-title" }, [
                                    _vm._v(
                                      _vm._s(_vm._f("day")(timing.day)) +
                                        ", " +
                                        _vm._s(
                                          _vm._f("trim")(timing.start_time)
                                        ) +
                                        " – " +
                                        _vm._s(
                                          _vm._f("trim")(timing.end_time)
                                        ) +
                                        ", " +
                                        _vm._s(timing.location.title) +
                                        " "
                                    )
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.course.is_event
                                ? _c("span", { staticClass: "box-title" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("date")(_vm.course.start_date)
                                      ) +
                                        " – " +
                                        _vm._s(
                                          _vm._f("date")(_vm.course.end_date)
                                        ) +
                                        ", " +
                                        _vm._s(timing.location.title) +
                                        " "
                                    )
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "span",
                                { staticClass: "box-subtitle box-multiline" },
                                [
                                  _vm._v(
                                    "\n                lektoři " +
                                      _vm._s(timing.instructor.name) +
                                      ", " +
                                      _vm._s(timing.help_instructor.name)
                                  ),
                                  _c("br"),
                                  _vm._v(
                                    "\n                Zbývá " +
                                      _vm._s(timing.remaining_capacity) +
                                      " míst z\n                " +
                                      _vm._s(timing.capacity) +
                                      "\n              "
                                  )
                                ]
                              )
                            ]),
                            _vm._v(" "),
                            _vm.course.available === "NOT_AVAILABLE"
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "col-md-auto box-right align-items-center d-flex"
                                  },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "timing-occupied" },
                                      [_vm._v("Bude otevřeno")]
                                    )
                                  ]
                                )
                              : timing.remaining_capacity > 0
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "col-md-auto box-right align-items-center d-flex"
                                  },
                                  [
                                    _vm.isValidSelection(timing.type) &&
                                    !_vm.isTimingSelected(timing.id)
                                      ? _c(
                                          "a",
                                          {
                                            staticClass: "btn btn-primary",
                                            attrs: { href: "#" },
                                            on: {
                                              click: function($event) {
                                                $event.preventDefault()
                                                return _vm.selectTiming(
                                                  timing.id
                                                )
                                              }
                                            }
                                          },
                                          [_vm._v("Vybrat")]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.isTimingSelected(timing.id)
                                      ? _c(
                                          "a",
                                          {
                                            staticClass:
                                              "btn btn-primary btn-selected",
                                            attrs: { href: "#" },
                                            on: {
                                              click: function($event) {
                                                $event.preventDefault()
                                                return _vm.deselectTiming(
                                                  timing.id
                                                )
                                              }
                                            }
                                          },
                                          [_vm._v("Zrušit")]
                                        )
                                      : _vm._e()
                                  ]
                                )
                              : timing.remaining_capacity <= 0
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "col-md-auto box-right align-items-center d-flex"
                                  },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "timing-occupied" },
                                      [_vm._v("Obsazeno")]
                                    )
                                  ]
                                )
                              : !_vm.isValidSelection(timing.type) &&
                                !_vm.isTimingSelected(timing.id)
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "col-md-auto box-right align-items-center d-flex"
                                  },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "timing-occupied" },
                                      [_vm._v("Vyčerpané možnosti")]
                                    )
                                  ]
                                )
                              : _vm._e()
                          ])
                        ])
                      ]
                    )
                  }),
                  _vm._v(" "),
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "required",
                          expression: "'required'"
                        }
                      ],
                      staticClass: "d-none",
                      attrs: {
                        "v:model": "selectedTimings",
                        name: "timing",
                        id: "timing",
                        multiple: ""
                      }
                    },
                    _vm._l(_vm.timings, function(timing) {
                      return _c(
                        "option",
                        {
                          key: timing.id,
                          domProps: {
                            value: timing.id,
                            selected: _vm.isTimingSelected(timing.id)
                          }
                        },
                        [
                          _vm._v(
                            "\n          " + _vm._s(timing.day) + "\n        "
                          )
                        ]
                      )
                    }),
                    0
                  ),
                  _vm._v(" "),
                  _vm.errors.has("timing")
                    ? _c("div", [_vm._v("Vyberte alespoň jeden čas kurzu")])
                    : _vm._e()
                ],
                2
              ),
              _vm._v(" "),
              _vm.selectedTimings.length > 0
                ? _c("div", [
                    _c("fieldset", { attrs: { id: "personal-informations" } }, [
                      _vm._m(0),
                      _vm._v(" "),
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-md-6" }, [
                          _c("div", { staticClass: "form-group" }, [
                            _vm._m(1),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: "required|alpha_spaces",
                                  expression: "'required|alpha_spaces'"
                                }
                              ],
                              staticClass: "form-control",
                              attrs: { name: "name", type: "text" }
                            }),
                            _vm._v(" "),
                            _vm.errors.has("name")
                              ? _c("div", { staticClass: "invalid-tooltip" }, [
                                  _vm._v("Křestní jméno je povinné")
                                ])
                              : _vm._e()
                          ])
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-md-6" }, [
                          _c("div", { staticClass: "form-group" }, [
                            _vm._m(2),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: "required|alpha_spaces",
                                  expression: "'required|alpha_spaces'"
                                }
                              ],
                              staticClass: "form-control",
                              attrs: { name: "surname", type: "text" }
                            }),
                            _vm._v(" "),
                            _vm.errors.has("surname")
                              ? _c("div", { staticClass: "invalid-tooltip" }, [
                                  _vm._v("Příjmení je povinné")
                                ])
                              : _vm._e()
                          ])
                        ])
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col" }, [
                          _c("div", { staticClass: "form-group" }, [
                            _vm._m(3),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: "required|numeric|personal_number",
                                  expression:
                                    "'required|numeric|personal_number'"
                                }
                              ],
                              staticClass: "form-control",
                              attrs: { name: "personal_id", type: "text" }
                            }),
                            _vm._v(" "),
                            _vm.errors.has("personal_id")
                              ? _c("div", { staticClass: "invalid-tooltip" }, [
                                  _vm._v("Rodné číslo je povinné")
                                ])
                              : _vm._e()
                          ])
                        ])
                      ]),
                      _vm._v(" "),
                      !_vm.onlyCibulkaSelected()
                        ? _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "col" }, [
                              _c("div", { staticClass: "form-group" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "custom-control custom-checkbox"
                                  },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.chs,
                                          expression: "chs"
                                        }
                                      ],
                                      staticClass: "custom-control-input",
                                      attrs: {
                                        type: "checkbox",
                                        name: "chs",
                                        id: "chs"
                                      },
                                      domProps: {
                                        checked: Array.isArray(_vm.chs)
                                          ? _vm._i(_vm.chs, null) > -1
                                          : _vm.chs
                                      },
                                      on: {
                                        change: function($event) {
                                          var $$a = _vm.chs,
                                            $$el = $event.target,
                                            $$c = $$el.checked ? true : false
                                          if (Array.isArray($$a)) {
                                            var $$v = null,
                                              $$i = _vm._i($$a, $$v)
                                            if ($$el.checked) {
                                              $$i < 0 &&
                                                (_vm.chs = $$a.concat([$$v]))
                                            } else {
                                              $$i > -1 &&
                                                (_vm.chs = $$a
                                                  .slice(0, $$i)
                                                  .concat($$a.slice($$i + 1)))
                                            }
                                          } else {
                                            _vm.chs = $$c
                                          }
                                        }
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "label",
                                      {
                                        staticClass: "custom-control-label",
                                        attrs: { for: "chs" }
                                      },
                                      [
                                        _vm._v(
                                          "Již jsem členem Horoguru oddílu"
                                        )
                                      ]
                                    )
                                  ]
                                )
                              ])
                            ])
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col" }, [
                          _c("div", { staticClass: "form-group" }, [
                            _c(
                              "div",
                              { staticClass: "custom-control custom-checkbox" },
                              [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.shoe,
                                      expression: "shoe"
                                    }
                                  ],
                                  staticClass: "custom-control-input",
                                  attrs: {
                                    type: "checkbox",
                                    name: "shoe",
                                    id: "shoe"
                                  },
                                  domProps: {
                                    checked: Array.isArray(_vm.shoe)
                                      ? _vm._i(_vm.shoe, null) > -1
                                      : _vm.shoe
                                  },
                                  on: {
                                    change: function($event) {
                                      var $$a = _vm.shoe,
                                        $$el = $event.target,
                                        $$c = $$el.checked ? true : false
                                      if (Array.isArray($$a)) {
                                        var $$v = null,
                                          $$i = _vm._i($$a, $$v)
                                        if ($$el.checked) {
                                          $$i < 0 &&
                                            (_vm.shoe = $$a.concat([$$v]))
                                        } else {
                                          $$i > -1 &&
                                            (_vm.shoe = $$a
                                              .slice(0, $$i)
                                              .concat($$a.slice($$i + 1)))
                                        }
                                      } else {
                                        _vm.shoe = $$c
                                      }
                                    }
                                  }
                                }),
                                _vm._v(" "),
                                _c(
                                  "label",
                                  {
                                    staticClass: "custom-control-label",
                                    attrs: { for: "shoe" }
                                  },
                                  [_vm._v("Chci půjčit lezečky")]
                                )
                              ]
                            )
                          ])
                        ])
                      ]),
                      _vm._v(" "),
                      _vm.shoe
                        ? _c("div", { staticClass: "row" }, [_vm._m(4)])
                        : _vm._e()
                    ]),
                    _vm._v(" "),
                    _vm.course.child_course
                      ? _c("fieldset", { attrs: { id: "child-course" } }, [
                          _vm._m(5),
                          _vm._v(" "),
                          _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "col-md-6" }, [
                              _c("div", { staticClass: "form-group" }, [
                                _vm._m(6),
                                _vm._v(" "),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "validate",
                                      rawName: "v-validate",
                                      value: _vm.course.child_course
                                        ? "required|alpha_spaces"
                                        : "",
                                      expression:
                                        "course.child_course ? 'required|alpha_spaces' : ''"
                                    }
                                  ],
                                  staticClass: "form-control",
                                  attrs: {
                                    type: "text",
                                    name: "parent_name",
                                    id: "parent_name"
                                  }
                                }),
                                _vm._v(" "),
                                _vm.errors.has("parent_name")
                                  ? _c(
                                      "div",
                                      { staticClass: "invalid-tooltip" },
                                      [
                                        _vm._v(
                                          "Křestní jméno zástupce je povinné"
                                        )
                                      ]
                                    )
                                  : _vm._e()
                              ])
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "col-md-6" }, [
                              _c("div", { staticClass: "form-group" }, [
                                _vm._m(7),
                                _vm._v(" "),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "validate",
                                      rawName: "v-validate",
                                      value: _vm.course.child_course
                                        ? "required|alpha_spaces"
                                        : "",
                                      expression:
                                        "course.child_course ? 'required|alpha_spaces' : ''"
                                    }
                                  ],
                                  staticClass: "form-control",
                                  attrs: {
                                    type: "text",
                                    name: "parent_surname",
                                    id: "parent_surname"
                                  }
                                }),
                                _vm._v(" "),
                                _vm.errors.has("parent_surname")
                                  ? _c(
                                      "div",
                                      { staticClass: "invalid-tooltip" },
                                      [_vm._v("Příjmení zástupce je povinné")]
                                    )
                                  : _vm._e()
                              ])
                            ])
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "col-md-6" }, [
                              _c("div", { staticClass: "form-group" }, [
                                _vm._m(8),
                                _vm._v(" "),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "validate",
                                      rawName: "v-validate",
                                      value: _vm.course.child_course
                                        ? "required"
                                        : "",
                                      expression:
                                        "course.child_course ? 'required' : ''"
                                    }
                                  ],
                                  staticClass: "form-control",
                                  attrs: {
                                    type: "tel",
                                    name: "phone",
                                    id: "phone"
                                  }
                                }),
                                _vm._v(" "),
                                _vm.errors.has("phone")
                                  ? _c(
                                      "div",
                                      { staticClass: "invalid-tooltip" },
                                      [_vm._v("Telefon je povinný")]
                                    )
                                  : _vm._e()
                              ])
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "col-md-6" }, [
                              _c("div", { staticClass: "form-group" }, [
                                _vm._m(9),
                                _vm._v(" "),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "validate",
                                      rawName: "v-validate",
                                      value: _vm.course.child_course
                                        ? "required|email"
                                        : "",
                                      expression:
                                        "course.child_course ? 'required|email' : ''"
                                    }
                                  ],
                                  staticClass: "form-control",
                                  attrs: {
                                    type: "email",
                                    name: "email",
                                    id: "email"
                                  }
                                }),
                                _vm._v(" "),
                                _vm.errors.has("email")
                                  ? _c(
                                      "div",
                                      { staticClass: "invalid-tooltip" },
                                      [_vm._v("E-mail je povinný")]
                                    )
                                  : _vm._e()
                              ])
                            ])
                          ]),
                          _vm._v(" "),
                          !_vm.onlyCibulkaSelected()
                            ? _c("fieldset", { attrs: { id: "chsInfo" } }, [
                                _c("div", { staticClass: "row" }, [
                                  _c("div", { staticClass: "col-md-9" }, [
                                    _c("div", { staticClass: "form-group" }, [
                                      _vm._m(10),
                                      _vm._v(" "),
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "validate",
                                            rawName: "v-validate",
                                            value: "required",
                                            expression: "'required'"
                                          }
                                        ],
                                        staticClass: "form-control",
                                        attrs: {
                                          type: "text",
                                          name: "chs_street",
                                          id: "chs_street"
                                        }
                                      }),
                                      _vm._v(" "),
                                      _vm.errors.has("chs_street")
                                        ? _c(
                                            "div",
                                            { staticClass: "invalid-tooltip" },
                                            [_vm._v("Ulice je povinná")]
                                          )
                                        : _vm._e()
                                    ])
                                  ]),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "col" }, [
                                    _c("div", { staticClass: "form-group" }, [
                                      _vm._m(11),
                                      _vm._v(" "),
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "validate",
                                            rawName: "v-validate",
                                            value: "required",
                                            expression: "'required'"
                                          }
                                        ],
                                        staticClass: "form-control",
                                        attrs: {
                                          type: "text",
                                          name: "chs_house_number",
                                          id: "chs_house_number"
                                        }
                                      }),
                                      _vm._v(" "),
                                      _vm.errors.has("chs_house_number")
                                        ? _c(
                                            "div",
                                            { staticClass: "invalid-tooltip" },
                                            [_vm._v("Číslo popisné je povinné")]
                                          )
                                        : _vm._e()
                                    ])
                                  ])
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "row" }, [
                                  _c("div", { staticClass: "col-md-9" }, [
                                    _c("div", { staticClass: "form-group" }, [
                                      _vm._m(12),
                                      _vm._v(" "),
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "validate",
                                            rawName: "v-validate",
                                            value: "required",
                                            expression: "'required'"
                                          }
                                        ],
                                        staticClass: "form-control",
                                        attrs: {
                                          type: "text",
                                          name: "chs_city",
                                          id: "chs_city"
                                        }
                                      }),
                                      _vm._v(" "),
                                      _vm.errors.has("chs_city")
                                        ? _c(
                                            "div",
                                            { staticClass: "invalid-tooltip" },
                                            [_vm._v("Město je povinné")]
                                          )
                                        : _vm._e()
                                    ])
                                  ]),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "col" }, [
                                    _c("div", { staticClass: "form-group" }, [
                                      _vm._m(13),
                                      _vm._v(" "),
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "validate",
                                            rawName: "v-validate",
                                            value: "required",
                                            expression: "'required'"
                                          }
                                        ],
                                        staticClass: "form-control",
                                        attrs: {
                                          type: "text",
                                          name: "chs_zip_code",
                                          id: "chs_zip_code"
                                        }
                                      }),
                                      _vm._v(" "),
                                      _vm.errors.has("chs_zip_code")
                                        ? _c(
                                            "div",
                                            { staticClass: "invalid-tooltip" },
                                            [_vm._v("PSČ je povinné")]
                                          )
                                        : _vm._e()
                                    ])
                                  ])
                                ])
                              ])
                            : _vm._e()
                        ])
                      : _c("fieldset", [
                          _c("div", { staticClass: "row" }, [
                            _vm._m(14),
                            _vm._v(" "),
                            _c("div", { staticClass: "col-md-6" }, [
                              _c("div", { staticClass: "form-group" }, [
                                _vm._m(15),
                                _vm._v(" "),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "validate",
                                      rawName: "v-validate",
                                      value: "required",
                                      expression: "'required'"
                                    }
                                  ],
                                  staticClass: "form-control",
                                  attrs: {
                                    type: "tel",
                                    name: "phone",
                                    id: "phone"
                                  }
                                }),
                                _vm._v(" "),
                                _vm.errors.has("phone")
                                  ? _c(
                                      "div",
                                      { staticClass: "invalid-tooltip" },
                                      [_vm._v("Telefon je povinný")]
                                    )
                                  : _vm._e()
                              ])
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "col-md-6" }, [
                              _c("div", { staticClass: "form-group" }, [
                                _vm._m(16),
                                _vm._v(" "),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "validate",
                                      rawName: "v-validate",
                                      value: !_vm.course.child_course
                                        ? "required|email"
                                        : "",
                                      expression:
                                        "!course.child_course ? 'required|email' : ''"
                                    }
                                  ],
                                  staticClass: "form-control",
                                  attrs: {
                                    type: "email",
                                    name: "email",
                                    id: "email"
                                  }
                                }),
                                _vm._v(" "),
                                _vm.errors.has("email")
                                  ? _c(
                                      "div",
                                      { staticClass: "invalid-tooltip" },
                                      [_vm._v("E-mail je povinný")]
                                    )
                                  : _vm._e()
                              ])
                            ])
                          ])
                        ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col" }, [
                        _c("div", { staticClass: "form-group" }, [
                          _c(
                            "div",
                            { staticClass: "custom-control custom-checkbox" },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.invoice,
                                    expression: "invoice"
                                  }
                                ],
                                staticClass: "custom-control-input",
                                attrs: {
                                  type: "checkbox",
                                  name: "invoice",
                                  id: "invoice"
                                },
                                domProps: {
                                  checked: Array.isArray(_vm.invoice)
                                    ? _vm._i(_vm.invoice, null) > -1
                                    : _vm.invoice
                                },
                                on: {
                                  change: function($event) {
                                    var $$a = _vm.invoice,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? true : false
                                    if (Array.isArray($$a)) {
                                      var $$v = null,
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          (_vm.invoice = $$a.concat([$$v]))
                                      } else {
                                        $$i > -1 &&
                                          (_vm.invoice = $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1)))
                                      }
                                    } else {
                                      _vm.invoice = $$c
                                    }
                                  }
                                }
                              }),
                              _vm._v(" "),
                              _c(
                                "label",
                                {
                                  staticClass: "custom-control-label",
                                  attrs: { for: "invoice" }
                                },
                                [_vm._v("Chci vystavit fakturu")]
                              )
                            ]
                          )
                        ])
                      ])
                    ]),
                    _vm._v(" "),
                    _vm.invoice
                      ? _c("fieldset", { attrs: { id: "invoice" } }, [
                          _vm._m(17),
                          _vm._v(" "),
                          _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "col-md-6" }, [
                              _c("div", { staticClass: "form-group" }, [
                                _vm._m(18),
                                _vm._v(" "),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "validate",
                                      rawName: "v-validate",
                                      value: _vm.invoice
                                        ? "required|numeric"
                                        : "",
                                      expression:
                                        "invoice ? 'required|numeric' : ''"
                                    }
                                  ],
                                  staticClass: "form-control",
                                  attrs: { name: "ico", type: "text" }
                                }),
                                _vm._v(" "),
                                _vm.errors.has("ico")
                                  ? _c(
                                      "div",
                                      { staticClass: "invalid-tooltip" },
                                      [_vm._v("IČO je povinné")]
                                    )
                                  : _vm._e()
                              ])
                            ])
                          ]),
                          _vm._v(" "),
                          _vm._m(19),
                          _vm._v(" "),
                          _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "col-md-9" }, [
                              _c("div", { staticClass: "form-group" }, [
                                _vm._m(20),
                                _vm._v(" "),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "validate",
                                      rawName: "v-validate",
                                      value: _vm.invoice ? "required" : "",
                                      expression: "invoice ? 'required' : ''"
                                    }
                                  ],
                                  staticClass: "form-control",
                                  attrs: {
                                    type: "text",
                                    name: "street",
                                    id: "street"
                                  }
                                }),
                                _vm._v(" "),
                                _vm.errors.has("street")
                                  ? _c(
                                      "div",
                                      { staticClass: "invalid-tooltip" },
                                      [_vm._v("Ulice je povinnná")]
                                    )
                                  : _vm._e()
                              ])
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "col" }, [
                              _c("div", { staticClass: "form-group" }, [
                                _vm._m(21),
                                _vm._v(" "),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "validate",
                                      rawName: "v-validate",
                                      value: _vm.invoice ? "required" : "",
                                      expression: "invoice ? 'required' : ''"
                                    }
                                  ],
                                  staticClass: "form-control",
                                  attrs: {
                                    type: "text",
                                    name: "house_number",
                                    id: "house_number"
                                  }
                                }),
                                _vm._v(" "),
                                _vm.errors.has("house_number")
                                  ? _c(
                                      "div",
                                      { staticClass: "invalid-tooltip" },
                                      [_vm._v("Čislo popisné je povinné")]
                                    )
                                  : _vm._e()
                              ])
                            ])
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "col-md-9" }, [
                              _c("div", { staticClass: "form-group" }, [
                                _vm._m(22),
                                _vm._v(" "),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "validate",
                                      rawName: "v-validate",
                                      value: _vm.invoice ? "required" : "",
                                      expression: "invoice ? 'required' : ''"
                                    }
                                  ],
                                  staticClass: "form-control",
                                  attrs: {
                                    type: "text",
                                    name: "city",
                                    id: "city"
                                  }
                                }),
                                _vm._v(" "),
                                _vm.errors.has("city")
                                  ? _c(
                                      "div",
                                      { staticClass: "invalid-tooltip" },
                                      [_vm._v("Město je povinné")]
                                    )
                                  : _vm._e()
                              ])
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "col" }, [
                              _c("div", { staticClass: "form-group" }, [
                                _vm._m(23),
                                _vm._v(" "),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "validate",
                                      rawName: "v-validate",
                                      value: _vm.invoice ? "required" : "",
                                      expression: "invoice ? 'required' : ''"
                                    }
                                  ],
                                  staticClass: "form-control",
                                  attrs: {
                                    type: "text",
                                    name: "zip_code",
                                    id: "zip_code"
                                  }
                                }),
                                _vm._v(" "),
                                _vm.errors.has("zip_code")
                                  ? _c(
                                      "div",
                                      { staticClass: "invalid-tooltip" },
                                      [_vm._v("PSČ je povinné")]
                                    )
                                  : _vm._e()
                              ])
                            ])
                          ])
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("div", { staticClass: "row sign-in-container" }, [
                      _c("div", { staticClass: "col" }, [
                        _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "col-12" }, [
                            _c("div", { staticClass: "form-group" }, [
                              _vm._m(24),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "custom-control custom-checkbox terms-of-service"
                                },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "validate",
                                        rawName: "v-validate",
                                        value: "required",
                                        expression: "'required'"
                                      }
                                    ],
                                    staticClass: "custom-control-input",
                                    attrs: {
                                      type: "checkbox",
                                      name: "tos",
                                      id: "tos"
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "label",
                                    {
                                      staticClass: "custom-control-label",
                                      attrs: { for: "tos" }
                                    },
                                    [
                                      _vm._v(
                                        "\n                    Souhlasím s\n                    "
                                      ),
                                      _c(
                                        "a",
                                        {
                                          attrs: {
                                            href:
                                              "https://horoguru.cz/obchodni-podminky",
                                            target: "_blank"
                                          }
                                        },
                                        [_vm._v("podmínkami Horoguru")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        { staticClass: "label-required" },
                                        [_vm._v("*")]
                                      ),
                                      _vm._v(" "),
                                      _vm.errors.has("tos")
                                        ? _c(
                                            "div",
                                            { staticClass: "invalid-tooltip" },
                                            [
                                              _vm._v(
                                                "Je třeba souhlasit s podmínkami Horoguru"
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    ]
                                  )
                                ]
                              )
                            ])
                          ])
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "col" }, [
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-primary",
                                attrs: {
                                  disabled: _vm.submitDisabled,
                                  type: "submit"
                                }
                              },
                              [_vm._v("Přihlásit")]
                            )
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col final-price" }, [
                            _c("span", { staticClass: "price" }, [
                              _vm._v(_vm._s(_vm.finalPrice) + " Kč")
                            ])
                          ])
                        ])
                      ])
                    ]),
                    _vm._v(" "),
                    _vm.showDiscountNotice(_vm.course)
                      ? _c("div", { staticClass: "row" }, [_vm._m(25)])
                      : _vm._e()
                  ])
                : _vm._e()
            ]
          )
        : _c(
            "div",
            { staticClass: "application-finished" },
            [
              _c("h3", [_vm._v("Přihlášení proběhlo úspěšně!")]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "Děkujeme za přihlášení na kurz. Všechny důležité a doplňující informace Vám zašleme automaticky na zadanou emailovou adresu (zkontrolujte SPAM, občas nám to tam padá)."
                )
              ]),
              _vm._v(" "),
              _c("h4", [_vm._v("Jste přihlášeni na tyto kurzy")]),
              _vm._v(" "),
              _vm._l(_vm.timings, function(timing) {
                return [
                  _vm.isTimingSelected(timing.id)
                    ? _c("div", { key: timing.id, staticClass: "row" }, [
                        _c("div", { staticClass: "box box-info col-12" }, [
                          _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "col-6" }, [
                              !_vm.course.is_event
                                ? _c("span", { staticClass: "box-title" }, [
                                    _vm._v(
                                      _vm._s(_vm._f("day")(timing.day)) +
                                        ", " +
                                        _vm._s(
                                          _vm._f("trim")(timing.start_time)
                                        ) +
                                        " – " +
                                        _vm._s(
                                          _vm._f("trim")(timing.end_time)
                                        ) +
                                        ", " +
                                        _vm._s(timing.location.title)
                                    )
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.course.is_event
                                ? _c("span", { staticClass: "box-title" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("date")(_vm.course.start_date)
                                      ) +
                                        " – " +
                                        _vm._s(
                                          _vm._f("date")(_vm.course.end_date)
                                        ) +
                                        " "
                                    )
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "span",
                                { staticClass: "box-subtitle box-multiline" },
                                [
                                  _vm._v(
                                    "\n                lektoři " +
                                      _vm._s(timing.instructor.name) +
                                      ",\n                " +
                                      _vm._s(timing.help_instructor.name) +
                                      "\n              "
                                  )
                                ]
                              )
                            ])
                          ])
                        ])
                      ])
                    : _vm._e()
                ]
              }),
              _vm._v(" "),
              _c("h4", [_vm._v("Cena")]),
              _vm._v(" "),
              _c("p", [
                _vm._v("\n      Výsledná cena: "),
                _c("span", { staticClass: "price" }, [
                  _vm._v(_vm._s(_vm.finalPrice) + " Kč")
                ])
              ]),
              _vm._v(" "),
              !_vm.course.is_event
                ? _c("p", { staticClass: "text-muted" }, [
                    _vm._v(
                      "\n      Výsledná cena je beze slev. Pokud chcete uplatnit slevu na sourozence, napište email na\n      "
                    ),
                    _c("a", { attrs: { href: "mailto:info@horoguru.cz" } }, [
                      _vm._v("info@horoguru.cz")
                    ]),
                    _vm._v(" a kurz zaplaťte poté.\n    ")
                  ])
                : _vm._e(),
              _vm._v(" "),
              !_vm.course.is_event
                ? _c("h4", [_vm._v("Dobré vědět")])
                : _vm._e(),
              _vm._v(" "),
              !_vm.course.is_event
                ? _c("ul", [
                    _c("li", [
                      _vm._v("\n        Kroužky začínají v týdnu od\n        "),
                      _c("strong", [
                        _vm._v(_vm._s(_vm._f("date")(_vm.course.start_date)))
                      ]),
                      _vm._v(
                        ". Během školních prázdnin a svátků kroužky nejsou. Pololetí končí "
                      ),
                      _c("strong", [
                        _vm._v(_vm._s(_vm._f("date")(_vm.course.end_date)))
                      ]),
                      _vm._v(".\n      ")
                    ]),
                    _vm._v(" "),
                    _vm._m(26),
                    _vm._v(" "),
                    _vm._m(27),
                    _vm._v(" "),
                    _vm._m(28)
                  ])
                : _vm._e()
            ],
            2
          )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col" }, [_c("h2", [_vm._v("Údaje o lezci")])])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { attrs: { for: "name" } }, [
      _vm._v("Křestní jméno "),
      _c("span", { staticClass: "label-required" }, [_vm._v("*")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { attrs: { for: "surname" } }, [
      _vm._v("Příjmení "),
      _c("span", { staticClass: "label-required" }, [_vm._v("*")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { attrs: { for: "personal_id" } }, [
      _vm._v("Rodné číslo "),
      _c("small", { staticClass: "text-muted" }, [_vm._v("(bez lomítka)")]),
      _vm._v(" "),
      _c("span", { staticClass: "label-required" }, [_vm._v("*")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-md-6" }, [
      _c("div", { staticClass: "form-group" }, [
        _c("label", { attrs: { for: "foot_size" } }, [
          _vm._v("Velikost nohy "),
          _c("span", { staticClass: "label-required" }, [_vm._v("*")])
        ]),
        _vm._v(" "),
        _c(
          "select",
          { staticClass: "form-control", attrs: { name: "foot_size" } },
          [
            _c("option", { attrs: { value: "28" } }, [_vm._v("28")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "29" } }, [_vm._v("29")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "30" } }, [_vm._v("30")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "31" } }, [_vm._v("31")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "32" } }, [_vm._v("32")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "33" } }, [_vm._v("33")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "34" } }, [_vm._v("34")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "35" } }, [_vm._v("35")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "36" } }, [_vm._v("36")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "37" } }, [_vm._v("37")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "38" } }, [_vm._v("38")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "39" } }, [_vm._v("39")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "40" } }, [_vm._v("40")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "41" } }, [_vm._v("41")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "42" } }, [_vm._v("42")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "43" } }, [_vm._v("43")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "44" } }, [_vm._v("44")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "45" } }, [_vm._v("45")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "46" } }, [_vm._v("46")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "47" } }, [_vm._v("47")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "48" } }, [_vm._v("48")])
          ]
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col" }, [
        _c("h2", [_vm._v("Údaje o zákonném zástupci")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { attrs: { for: "parent_name" } }, [
      _vm._v("Křestní jméno zástupce "),
      _c("span", { staticClass: "label-required" }, [_vm._v("*")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { attrs: { for: "parent_surname" } }, [
      _vm._v("Příjmení zástupce "),
      _c("span", { staticClass: "label-required" }, [_vm._v("*")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { attrs: { for: "phone" } }, [
      _vm._v("Telefonní číslo na zástupce "),
      _c("span", { staticClass: "label-required" }, [_vm._v("*")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { attrs: { for: "email" } }, [
      _vm._v("E-mail na zástupce "),
      _c("span", { staticClass: "label-required" }, [_vm._v("*")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { attrs: { for: "chs_street" } }, [
      _vm._v("Ulice "),
      _c("span", { staticClass: "label-required" }, [_vm._v("*")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { attrs: { for: "chs_house_number" } }, [
      _vm._v("Číslo popisné "),
      _c("span", { staticClass: "label-required" }, [_vm._v("*")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { attrs: { for: "chs_city" } }, [
      _vm._v("Město "),
      _c("span", { staticClass: "label-required" }, [_vm._v("*")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { attrs: { for: "chs_zip_code" } }, [
      _vm._v("PSČ "),
      _c("span", { staticClass: "label-required" }, [_vm._v("*")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-12" }, [_c("h2", [_vm._v("Kontakt")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { attrs: { for: "phone" } }, [
      _vm._v("Telefonní číslo "),
      _c("span", { staticClass: "label-required" }, [_vm._v("*")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { attrs: { for: "email" } }, [
      _vm._v("E-mail "),
      _c("span", { staticClass: "label-required" }, [_vm._v("*")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col" }, [
        _c("h2", [_vm._v("Fakturační údaje")]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "Údaje slouží pouze jako podklad k vytvoření faktury, o vystavení nám prosíme napište na email "
          ),
          _c("a", { attrs: { href: "mailto:info@horoguru.cz" } }, [
            _vm._v("info@horoguru.cz")
          ]),
          _vm._v(". Děkujeme.")
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { attrs: { for: "ico" } }, [
      _vm._v("IČO "),
      _c("span", { staticClass: "label-required" }, [_vm._v("*")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col" }, [
        _c("div", { staticClass: "form-group" }, [
          _c("label", { attrs: { for: "dic" } }, [_vm._v("DIČ")]),
          _vm._v(" "),
          _c("input", {
            staticClass: "form-control",
            attrs: { name: "dic", type: "text" }
          })
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { attrs: { for: "street" } }, [
      _vm._v("Ulice "),
      _c("span", { staticClass: "label-required" }, [_vm._v("*")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { attrs: { for: "house_number" } }, [
      _vm._v("Číslo popisné "),
      _c("span", { staticClass: "label-required" }, [_vm._v("*")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { attrs: { for: "city" } }, [
      _vm._v("Město "),
      _c("span", { staticClass: "label-required" }, [_vm._v("*")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { attrs: { for: "zip_code" } }, [
      _vm._v("PSČ "),
      _c("span", { staticClass: "label-required" }, [_vm._v("*")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "custom-control custom-checkbox terms-of-service" },
      [
        _c("input", {
          staticClass: "custom-control-input",
          attrs: {
            type: "checkbox",
            name: "newsletter",
            id: "newsletter-check",
            checked: ""
          }
        }),
        _vm._v(" "),
        _c(
          "label",
          {
            staticClass: "custom-control-label",
            attrs: { for: "newsletter-check" }
          },
          [_vm._v("Chci dostávat novinky o Horoguru")]
        )
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col" }, [
      _c("p", { staticClass: "text-muted" }, [
        _vm._v(
          "\n            * cena je finální, ale bez jakkýchkoliv slev. Pokud chcete uplatnit slevu na sourozence, napište email na\n            "
        ),
        _c("a", { attrs: { href: "mailto:info@horoguru.cz" } }, [
          _vm._v("info@horoguru.cz")
        ]),
        _vm._v(".\n          ")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _vm._v(
        "\n        Pokud Vaše dítě navštěvuje náš horokroužek již delší dobu (rok a déle) a počítá s lezením i nadále,\n        "
      ),
      _c("strong", [_vm._v("prosíme o zakoupení vlastních lezeček")]),
      _vm._v(", a to především z hygienických důvodů.\n      ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _c("strong", [_vm._v("Pro začátečníky")]),
      _vm._v(
        ", kteří si svou dlouhodobou účastí zatím nejsou jisti, samozřejmě lezečky i ostatní lezecký materiál k zapůjčení mít budeme."
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _vm._v("Na kroužek se chodí "),
      _c("strong", [_vm._v("v pohodlném sportovním oblečení")]),
      _vm._v(", doporučujeme mít s sebou i pití a v zimním období mikinu.")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }